<template>
  <article class="teaser order-teaser" :class="{'opacity-50' : !order.item_id}" v-if="order">
    <h4>
      Ordine #{{order.id}}
      <time>{{timeFormatter(order.timestamp_emissione)}}</time>
    </h4>
    <h5 :class="{'text-red-500' : !order.item_id}">
      ‣ <b>{{order.agreement_no}}</b> | P.V. <b>{{order.pdv_id}}</b> - <small
        class="text-xs">{{getPvName(order.pdv_id)}}</small>
    </h5>
    <hr>
    <ul v-if="order.item_id">
      <li>
        <ul>
          <li><label class="font-bold">‣ Articolo: </label><span>{{order.item_id}}</span></li>
          <li v-if="desc"><label>Descrizione: </label><span class="text-xs truncate leading-6 pl-2"
              :title="desc">{{desc}}</span></li>
          <li><label>Quantità: </label><span>{{order.quantity}}</span></li>
          <li v-if="shownFields.includes('loyalty_card') && order.loyalty_card"><label>N° Carta:
            </label><span>{{order.loyalty_card}}</span></li>
          <li>
            <label>Spedizione: </label><span class="text-xs">{{order.email ? order.email : '-'}}<button v-if="order.email && status === '20 Inviato'" class="send-again" @click.prevent="toggleMailOverlay">✉</button></span>
            <div class="mail-overlay" v-if="mailToggle">
              <h6 v-if="!loading">Vuoi DAVVERO spedire di nuovo il Voucher all'indirizzo <b>{{order.email}}</b>? (azione irreversibile)</h6>
              <a v-if="!loading" class="ok" href="#" @click.prevent="sendAgain">Sì, confermo</a>
              <a v-if="!loading" class="not-ok" href="#" @click.prevent="toggleMailOverlay">No, annulla</a>
              <v-icon v-if="loading" name="fa-spinner" class="animate-spin text-gray-400 w-20" />
            </div>
          </li>
          <li v-if="shownFields.includes('customer_firstname') || shownFields.includes('customer_lastname')">
            <label class="mr-2">Dati anagrafici:</label>
            <span v-if="order.customer_firstname || order.customer_lastname" class="text-right text-sm">
              {{order.customer_firstname ? order.customer_firstname : ''}} {{order.customer_lastname ?
              order.customer_lastname : ''}}
            </span>
          </li>
          <li
            v-if="shownFields.includes('customer_business_name')">
            <label>Ragione sociale: </label>
            <span v-if="order.customer_business_name">
              {{order.customer_business_name ? order.customer_business_name : ''}}
            </span>
          </li>
          <li
            v-if="shownFields.includes('customer_client_code')">
            <label>Codice cliente: </label>
            <span v-if="order.customer_client_code">
              {{order.customer_client_code ? order.customer_client_code : ''}}
            </span>
          </li>
          <li
            v-if="shownFields.includes('customer_phone_number')">
            <label>Numero di telefono: </label>
            <span v-if="order.customer_phone_number">
              {{order.customer_phone_number ? order.customer_phone_number : ''}}
            </span>
          </li>
        </ul>
      </li>
    </ul>
    <ul v-else>
      <li>
        <ul>
          <li><label class="font-bold text-red-500">‣ ORDINE NON VALIDO</label></li>
        </ul>
      </li>
    </ul>
  </article>
</template>

<script>
import moment from 'moment-timezone'
moment.locale('it')

export default {
  props: ['order', 'desc', 'pvs'],
  data: () => ({
    mailToggle: false,
    status: '20 Inviato',
    loading: false
  }),
  methods: {
    async getStatus () {
      await this.$store.dispatch('getStatusOrder', this.order.id)
        .then((rsp) => {
          this.status = rsp.msg
        })
    },
    toggleMailOverlay () {
      this.mailToggle = !this.mailToggle
    },
    async sendAgain () {
      this.loading = true
      await this.$store.dispatch('resendVoucher', {
        id: this.order.id
      })
        .then((rsp) => {
          this.mailToggle = false

          if (!rsp) {
            const error = true
            const msg = 'Errore di invio del Voucher: riprovare più tardi.'
            this.emitter.emit('fireAlert', { msg, error })
          } else {
            this.status = null
            const error = false
            const msg = 'Voucher inviato nuovamente all\'indirizzo: ' + this.order.email
            this.emitter.emit('fireAlert', { msg, error })
          }
        })
    },
    timeFormatter (time) {
      return moment.tz(time + 'Z', 'Europe/Rome').format('LLL')
    },
    getPvName (pvCode) {
      const target = this.pvs.filter((el) => {
        return el.code === pvCode
      })

      if (target && target[0]) return '(' + target[0].name + ')'
      else return null
    }
  },
  computed: {
    shownFields () {
      let extraData = this.$store.state.userData ? this.$store.state.userData.extra_data : null
      if (extraData) {
        extraData = JSON.parse(extraData)
        return extraData.shown_fields ? extraData.shown_fields : []
      } else {
        return []
      }
    }
  },
  async mounted () {
    // console.log('order', this.order)
    await this.getStatus()
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/teaser-order.scss";
</style>
