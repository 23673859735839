<template>
  <div class="advanced-search" v-click-outside="onClickOutside" v-if="!loading">
    <input
      type="text"
      v-model="currentSearch"
      :placeholder="placeholder"
      @click="showList = true"
    >
    <div class="search-list" v-if="showList">
      <span
        v-for="el in currentList"
        :key="el.id"
        @click="(index || index === 0) ? changeVal(el, el[info]) : changeVal(el[filter], el[info])"
        :title="el[info]"
      >
        {{el[filter]}} <small class="text-xs truncate" v-if="el[info]">- {{el[info]}}</small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list', 'filter', 'info', 'placeholder', 'index', 'overrider', 'counter'],
  data () {
    return {
      currentSearch: null,
      showList: false,
      loading: false
    }
  },
  computed: {
    currentList () {
      if (this.currentSearch) {
        return this.list.filter((el) => {
          const filterByFilter = this.filter && el[this.filter].toLowerCase().includes(this.currentSearch.toLowerCase())
          const filterByInfo = this.info && el[this.info].toLowerCase().includes(this.currentSearch.toLowerCase())
          return filterByFilter || filterByInfo
        })
      } else {
        return this.list
      }
    }
  },
  methods: {
    changeVal (val, info) {
      if (this.index || this.index === 0) {
        this.$emit('changeValue', { val, index: this.index })
        this.currentSearch = val[this.filter]
      } else {
        this.$emit('changeValue', val)
        this.currentSearch = val + ' - ' + info
      }
      this.showList = false
    },
    onClickOutside () {
      this.showList = false
    }
  },
  watch: {
    overrider (newVal) {
      if (newVal) this.currentSearch = newVal
    },
    counter () {
      this.currentSearch = null
    },
    currentSearch (newVal) {
      // console.log('CURRENT SEARCH', newVal)
      if (newVal === '' || newVal === null) {
        console.log('CURRENT SEARCH is null', newVal)
        this.$emit('changeValue', null)
      }
    }
    // currentList (newVal) {
    //   console.log('LIST', newVal)
    // }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/advanced-search.scss";
</style>
